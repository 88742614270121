import { MERCHANT_ENUM, MFA_SETTING_ENUM } from "@/config";

export type AuthUser = {
  id: number;
  email: string;
  phoneNo: string;
  emailVerificationToken: string;
  forgotPasswordToken: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  updatedAt: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  uid: string;
  blockList: number[];
  firstTradeDate: string;
  ordersCount: number;
  ordersPercentage: number;
  nickName: string;
  nameUpdatedAt: string;
  blockedBy: IBlockedBy[];
  mfaEnabled: boolean;
  settings: ISetting[];
  collateralDeposit: number;
  isMerchant: boolean;
  collateralToken: string;
  location: ILocation;
  merchant: IMerchant;
  country: string;
  countryOfResidence: string;
  isPopUpMarkedRead: boolean;
  userId: number;
};

export type IMerchant = {
  status: MERCHANT_ENUM;
};

export type ILocation = {
  city: string;
  country: string;
  countryCode: string;
  currency: string;
};

export type ISetting = {
  createdAt: string;
  id: number;
  key: MFA_SETTING_ENUM;
  updatedAt: string;
  value: string;
};

export type IBlockedBy = {
  id: number;
  user: {
    id: number;
  };
};
export type UserError = {
  message: string;
  statusCode: number;
};

export type User = AuthUser & UserError;

export type LoginResponse = {
  newLogin: boolean;
  token: string;
  user: AuthUser;
  message?: string;
  status?: number;
  mfaEnabled?: boolean;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
};

export type UserResponse = {
  token: string;
  user: User;
  message?: string;
  status?: number;
};

export enum UserModule {
  login = "/auth/login",
  register = "/user/register",
  currentUser = "/user",
  changePassword = "/user/change-password",
  forgotPassword = "/user/forgot-password",
  resetPassword = "/user/reset-password",
  emailConfirmation = "/user/confirm-email",
  emailVerification = "/user/email",
  uIDVerification = "/user/uid",
  resendEmail = "/user/resend-email",
  verifyOtp = "/user/verify-otp",
  resendOtp = "/user/resend-otp",
  block = "/user/block",
  report = "/user/report",
  feedback = "user/feedback",
  nickName = "user/name",
  resendOtpEmailPhone = "/user/otp",
  changePhone = "/user/phone",
  changeEmail = "/user/email",
  phoneVerification = "/user/phone",
  geeTest = "/auth/captcha",
  securityLogs = "/user/session-logs",
  EndSession = "/user/end-session",
  unlockAccount = "/auth/accountUnlock",
  UserById = "/user",
  freezeAccount = "/user/freeze-account",
  deleteAccount = "/user/delete-account",
  walletUser = "/wallet/user",
}

export type VerifyEmailResponse = {
  message: string;
  status: number;
};

export type ISoundResponse = {
  key: MFA_SETTING_ENUM;
  value: string;
};
