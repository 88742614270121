import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "@/lib/reactQuery";
import { AuthProvider } from "./contexts/AuthContext";
import { Provider } from "react-redux";
import { useStore } from "./state";
import { ToastProvider } from "./Providers/ToastProvider";
import { LanguageProvider } from "./contexts/Localization";
import { Suspense, useEffect } from "react";
import { Box, Flex } from "./components/elements";
import animationData from "@/lottie/loading.json";
import Lottie from "lottie-react";
const sitekey = import.meta.env.VITE_CAPACHA_SITE_KEY;

export default function Providers({ children }: React.PropsWithChildren) {
  const store = useStore();

  useEffect(() => {
    // Dynamically load reCAPTCHA script
    const loadRecaptchaScript = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    };

    loadRecaptchaScript();
  }, [sitekey]);
  return (
    <Provider store={store}>
      <LanguageProvider>
        <ToastProvider />
        <Suspense
          fallback={
            <Flex
              css={{
                minWidth: "100vw",
                minHeight: "100vh",
                background: "$bodyBg",
              }}
              justify={"center"}
              align={"center"}
            >
              <Box
                css={{
                  svg: {
                    w: "200px",
                    h: "200px",
                  },
                }}
              >
                <Lottie animationData={animationData} />
              </Box>
            </Flex>
          }
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthProvider>{children}</AuthProvider>
          </QueryClientProvider>
        </Suspense>
      </LanguageProvider>
    </Provider>
  );
}
