// import { blue, gray, teal } from "./colors";

const darkTheme = {
  colors: {
    //semantic colors
    primary1: "#04E2AF",
    primary2: "#04E2AF",
    primary3: "#FFFFFF",
    accent: "$teal300",
    fg: "$gray100",
    fgEmphasis: "$gray200",
    fgMuted: "$gray400",
    fgSubtle: "$gray300",
    fgOnAccent: "inherit",
    error: "#D95637",
    inactive: "#A1A1A1",
    headerBorder: "#FFFFFF80",
    btnOutlineColor: "#FFFFFF",
    linkBanner: "#1C2730",
    http: "#43B875",
    traderText: "#F1F1F1",
    priceText: "#01E2AF",
    forgotText: "#2DC2FB",
    googleBtnColor: "transparent",
    toggleThumb: "$black",
    inputPlaceholder: "#FFFFFF66",
    lineColor: "#E1E1E1",
    connectBox: "#1C2730",
    connectInput: "#9C6AFD",
    textBlue: "#04E2AF",
    textDark: "#04E2AF",
    textDark4: "#fff",
    textDark2: "#fff",
    textGray4: "#fff",
    textGray1: "#F1F1F1",
    textGray2: "#9da0a4",
    jobBoxBorder: "#9C6AFD33",
    jobContent: "#8F8F8F",
    jobRegion: "#BCBCBC",
    jobTypeBox: "#9c6afd1a",
    checkListBox: "#f9f9f91a",
    accordion: "#312f2f",
    lineColor2: "#E1E1E133",
    request: "#1C2730",
    cardBorder: "#9C6AFD80",
    textGray3: "#F1F1F1",
    invert: "#fff",
    // misc colors
    bodyBg: "$black",
    bodyText: "#F1F1F1",
    borderColor: "#04E2AF",
    placeholderBorder: "#CCCCCC",
    bgGradient: "126.13deg, #865CE6 -34.94%, #2F2488 93.99%",
    buttonGradient: "139.13deg, #9C6AFD 13.71%, #1B1773 76.8%",
    boxGradient: "139.13deg, $black 13.71%, $black 76.8%",
    connectButton: "101.43deg, #9C6AFD -9%, #1B1773 108.58%",
    footerBg: "#000000",
    logoColor: "$white",
    minBuying: "#578B9E",
    symbolColor: "#4A6CD1",
    aboutText: "#9da0a4",
    errorBox: "#393939",
    errorBorder: "#393939",
    label: "$white",
    missionIcon: "#04E2AF",
    textGray5: "#FFFFFF",
    strokeColor: "#A1A1A1",
    menuColor: "#1C2730",
    socialIcon: "#7E8C9F",
    balanceGradient: "0deg, rgba(38, 41, 237, 0.1), rgba(38, 41, 237, 0.1)",
    statistics: "#FFFFFF",
    tableHeader: "#1C2730",
    tableBorder: "#D9D9D933",
    tableRow: "#001711",
    securityDisable: "#F1F1F1",
    sendTab: "#001711",
    btcColor: "#F7931A",
    modalBg: "#1C2730",
    offerBg: "0deg, rgba(38, 41, 237, 0.1), rgba(38, 41, 237, 0.1)",
    offerHeading: "$white",
    dropdownBg: "transparent",
    selection: "transparent",
    lctColor: "#9D6AFC",
    marketTable: "#26184085",
    landingBg: 'url("/images/landing/bannerdark.png") no-repeat',
    bluebg: "#08022D",
    traderTop: "#26184085",
    traderSvg: "$white",
    toggle2Thumb: "#43B875",
    toggle3Thumb: "#7E8C9F",
    selectHover: "#04E2AF1a",
    blogBg: "#04E2AF1a",
    inputCardBackground: "#171717",
    mediumSecurity: "#FBC55A",
    highSecurity: "#04E2AF",
    lowSecurity: "#E35561",
    toggle1Bg: "#49A3E9",
    toggle2Bg: "#808080",
    indicator: "#312929",
    warnIndi: "#FFEBEB",
    blogSide: "none",
    borderColor1: "#04E2AF",
    selectionBg: "$black",
    hireGradient: "0deg, rgba(38, 41, 237, 0.1), rgba(38, 41, 237, 0.1)",
    currentColor: "white",
    disabledInput: "#04E2AF1a",
    lightblue1: "black",
    textGray17: "black",
    chatSender: "#9C6AFD",
    notiBg: "#26184085",
    verifyCard:
      "linear-gradient(0deg, rgba(38, 41, 237, 0.1), rgba(38, 41, 237, 0.1))",
    inputText: "#F2F2F7",
  },

  shadows: {
    xs: "10px 10px 20px rgba(0, 0, 0, 0.07)",
    sm: "0px 0px 20px rgba(0, 0, 0, 0.25)",
    md: "0px 0px 40px rgba(0, 0, 0, 0.25)",
    lg: "0px 0px 13px #9C6AFD80",
    xl: "0 40px 80px rgba(20, 20, 20, 0.25)",
    xxl: "0px 0px 13px #9C6AFD80",
  },
};

export default darkTheme;
