import { ITokenList } from "@/service/type";
import { REQUIRED, REQUIRED_ERROR } from "@/utils/errors";
import * as z from "zod";
import { VALIDATE_PHONE_NUMBER } from "../auth/components/type";
import { VERIFICATION_ENUM } from "../verification/types";
import { STRING_AND_NUMBER } from "@/utils/regex";
//enum for transaction type
export enum TransactionStatus {
  All = "ALL",
  Sent = "SENT", //withdraw
  Pending = "PENDING",
  Received = "RECEIVED", //deposit
  Cancelled = "CANCELLED",
  // Unconfirmed = "Unconfirmed",
}

export enum TRANSACTION_TYPES {
  DEPOSIT = "DEPOSIT", //recieved
  WITHDRAW = "WITHDRAW", //sent
  TRANSFER = "TRANSFER", //transfer negative is sent else recieve
  TRADE = "TRADE",
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
}

export enum Status {
  All = "",
  Sent = TRANSACTION_TYPES.WITHDRAW, //withdraw
  Received = TRANSACTION_TYPES.DEPOSIT,
  Pending = TRANSACTION_TYPES.PENDING, //deposit
}

export enum ActionTabs {
  Send = "Send",
  Receive = "Receive",
}

export enum ADDRESS_TYPE {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  UID = "UID",
}

export enum ADDRESS_LABEL {
  EMAIL = "Email",
  PHONE = "Phone",
  UID = "UID",
}

export enum DEPOSIT_TYPE {
  ADDRESS = "address",
  LOCALTRADERUSER_EMAIL = "localtraderuser_email",
  LOCALTRADERUSER_UID = "localtraderuser_uid",
  LOCALTRADERUSER_PHONE = "localtraderuser_phone",
}

export enum WalletModule {
  BLOCKCHAIN = "/wallet/blockchain",
  WITHDRAW = "/wallet/withdraw",
  TRANSFER = "/wallet/transfer",
  ADDRESS = "/wallet/address",
  BALANCE = "/ledger/balance",
  TOKEN_BALANCE = "/ledger/token-balance",
  HISTORY = "/ledger/history",
  USER_WALLET = "/wallet/user",
  REPORT_FUNDS = "community/abnormal-funds",
  PRODUCT_SUGGESTION = "community/product-suggestions",
  USER_LIMIT = "/wallet/withdraw-limit",
  MARKET_CHART = "/wallet/chart",
  USER_MIN_LIMIT = "/wallet/token/withdraw-limit",
}

export enum AuthModule {
  GET_2FA = "/auth/2fa",
  ENABLE_2FA = "/auth/2fa/enable",
  DISABLE_2FA = "/auth/2fa/disable",
  SETTING_2FA = "/user/settings",
  VERIFY_2FA = "/auth/2fa/verify",
  GET_2FA_CODE = "/auth/2fa/code",
  GET_2FA_CODE_LOGIN = "/auth/login/2fa/code",
  MARKED_POPUP_READ = "/user/mark-popup/read",
  LOGOUT = "/auth/logout",
}

export enum ActionWalletTabs {
  BALANCE = "balance",
  HISTORY = "history",
  SECURITY = "security",
}

export interface IWalletHistory {
  id: number;
  userId: number;
  amount: number;
  fromUser: any;
  toUser: any;
  txHash: string;
  type: string;
  symbol: string;
  tokenAddress: string;
  chainId: number;
  offerId: any;
  orderId: any;
  status: string;
  createdAt: string;
  updatedAt: string;
  listingStatus: string;
  token: ITokenList;
  transferredVia: string;
  fee: number;
}

export interface IMFASecurity {
  code: string;
  token: string;
  isRequired: boolean;
  type: VERIFICATION_ENUM;
  isVerified: boolean;
  [key: string]: string | boolean | VERIFICATION_ENUM;
}
export interface IWalletHistoryResponse {
  message: string;
  status: number;
  data: IWalletHistory[];
}

const typeEnum = z.enum([
  DEPOSIT_TYPE.ADDRESS,
  DEPOSIT_TYPE.LOCALTRADERUSER_EMAIL,
  DEPOSIT_TYPE.LOCALTRADERUSER_UID,
  DEPOSIT_TYPE.LOCALTRADERUSER_PHONE,
]);
const blockchainDataSchma = z.object({
  chainId: z.number(),
  id: z.number(),
  name: z.string(),
  rpcUrl: z.string(),
  imageUrl: z.string(),
  withdrawalFee: z.number(),
  minWithdrawalLimit: z.number(),
});

const cryptoObj = z.object({
  label: z.string(),
  image: z.string(),
  symbol: z.string(),
  decimals: z.number(),
  balance: z.number(),
  blockchains: z.array(blockchainDataSchma),
});

export const addressSchema = z.object({
  type: z.literal(typeEnum.enum.address),
  crypto: cryptoObj,
  network: z.object(
    {
      id: z.number(),
      name: z.string(),
      withdrawalFee: z.number(),
      minWithdrawalLimit: z.number(),
    },
    {
      required_error: REQUIRED_ERROR,
    }
  ),
  amount: REQUIRED(),
  address: z
    .string()
    .nonempty({
      message: REQUIRED_ERROR,
    })
    .regex(STRING_AND_NUMBER, { message: "Invalid wallet address" }),
});

export const userSchemaEmail = z.object({
  type: z.literal(typeEnum.enum.localtraderuser_email),
  email: z.string().email("Enter a valid email."),
  crypto: cryptoObj,
  amount: REQUIRED(),
});

export const userSchemaUid = z.object({
  type: z.literal(typeEnum.enum.localtraderuser_uid),
  uid: REQUIRED(),
  crypto: cryptoObj,
  amount: REQUIRED(),
});

export const userSchemaPhone = z.object({
  type: z.literal(typeEnum.enum.localtraderuser_phone),
  phone: VALIDATE_PHONE_NUMBER,
  crypto: cryptoObj,
  amount: REQUIRED(),
});

export const walletschema = z.discriminatedUnion("type", [
  addressSchema,
  userSchemaEmail,
  userSchemaUid,
  userSchemaPhone,
]);

export type WalletFormValues = z.infer<typeof walletschema>;
